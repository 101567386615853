
(function(){
  document.addEventListener("DOMContentLoaded", function() {
    var toggleButton = document.getElementById('menu-toggle');
    var menu = document.getElementById('menu');
    var isExpanded = toggleButton.classList.contains('fa-chevron-up');

    toggleButton.addEventListener('click', function(){
      menu.classList.toggle('header--active')
      toggleButton.classList.toggle('header-menu-button--active');
      isExpanded = !isExpanded;
    })
  });
})();
