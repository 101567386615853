(function(){

  'use strict'

  if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
    // load polyfill now
    return;
  }

  var entries = []
  document.addEventListener("DOMContentLoaded", function() {
    entries = document.querySelectorAll('[data-popup]');
    
    var io = new IntersectionObserver(function(entries) {
      for(var i = 0; i < entries.length; i ++) {
        if (entries[i].isIntersecting){
          entries[i].target.classList.add(entries[i].target.dataset.activeClass);
        } 
      }
    });

    for(var i = 0; i < entries.length; i ++) {
      entries[i].classList.add(entries[i].dataset.passiveClass);  
    }

    for(var i = 0; i < entries.length; i ++) {
      io.observe(entries[i]);
    };
  });
})();
