class CarrouselRotate {
  constructor() {}

  startAnimating(carouselElement) {
    const children = carouselElement.children;
    this.elements = children;
    this.showNext(null, children[0], 0, 100);
  }

  showNext(element, nextElement, count, delay) {
    if (element) {
      var duration = Math.floor(Math.random() * 400) + 100;
      var animation = element.animate(
        [
          {
            opacity: 1,
            transform: "rotate3d(1,0,0,-0deg) translateZ(10px) translateY(0px)",
            offset: 0
          },
          {
            opacity: 0,
            transform:
              "rotate3d(1,0,0,-85deg) translateZ(-10px) translateY(-50px)",
            offset: 1
          }
        ],
        {
          duration: duration,
          easing: "ease-in-out",
          fill: "forwards",
          delay: delay
        }
      );
    }
    if (nextElement) {
      var animation = nextElement.animate(
        [
          {
            opacity: 0,
            transform:
              "rotate3d(1,0,0,-85deg) translateZ(10px)  translateY(50px)",
            offset: 0
          },
          {
            opacity: 1,
            transform: "rotate3d(1,0,0,0deg) translateZ(-10px) translateY(0px)",
            offset: 1
          }
        ],
        {
          duration: duration,
          easing: "ease-in-out",
          fill: "forwards",
          delay: delay
        }
      );
    }

    animation.onfinish = () => {
      const next = count == this.elements.length - 1 ? 0 : count + 1;
      this.showNext(
        nextElement,
        this.elements[next],
        next,
        Math.floor(Math.random() * 7000) + 1000
      );
    };
  }
}

(function() {
  document.addEventListener("DOMContentLoaded", function() {
    const carouselElements = document.querySelectorAll(".carrousel-rotate");
    Array.from(carouselElements).forEach(carouselElement => {
      var carousel = new CarrouselRotate();
      carousel.startAnimating(carouselElement);
    });
  });
})();
