class CarrouselX {
  constructor(carrousel) {
    this.counter = 0;
    this.stopAutoScroll = false;
    this.goingBack = false;
    this.transition = -100;
    this.carousel = carrousel;
    this.numberOfElements = this.carousel.childElementCount;
  
    this.controlLeft = document
      .querySelector(".carousel-x-controls")
      .querySelector(".control-left");
    this.controlRight = document
      .querySelector(".carousel-x-controls")
      .querySelector(".control-right");

    this.showNextImage = this.showNextImage.bind(this);
    this.updateCounter = this.updateCounter.bind(this);
    this.updateCarousel = this.updateCarousel.bind(this);

    this.controlLeft.addEventListener("click", event => {
      this.stopAutoScroll = true;
      this.updateCounter(true);
      this.updateCarousel();
    });
    this.controlRight.addEventListener("click", event => {
      this.stopAutoScroll = true;
      this.updateCounter(false);
      this.updateCarousel();
    });
    window.setTimeout(this.showNextImage, 1000 * 10);
  }

  showNextImage() {
    if (this.stopAutoScroll) {
      return;
    }
    this.updateAutmatedScrolling();
    this.updateCarousel();

    window.setTimeout(this.showNextImage, 1000 * 10);
  }

  updateAutmatedScrolling() {
    this.counter += this.goingBack ? -1 : 1;
    if (this.counter === this.numberOfElements - 1) {
      this.goingBack = !this.goingBack;
    } else if (this.counter <= 0) {
      this.goingBack = !this.goingBack;
    }
  }

  updateCounter(goingBack) {
    this.counter += goingBack ? -1 : 1;
    if (this.counter >= this.numberOfElements) {
      this.counter = 0;
    } else if (this.counter <= -1) {
      this.counter = this.numberOfElements - 1;
    }
  }

  updateCarousel() {
    this.carousel.style.transform =
      "translateX(" + this.transition * this.counter + "%)";
  }
}

(function() {
  document.addEventListener("DOMContentLoaded", function() {
    var carousel = document.querySelector(".carousel-x");
    if (carousel === undefined || carousel === null) {
      return;
    }
    new CarrouselX(carousel);
  });
})();
